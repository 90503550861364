.rst__rowContents {
  min-width: 400px;
  box-shadow: none;
}
.rst__moveHandle {
  box-shadow: none;
}
.rst__placeholder {
  max-width: 100%;
}
.rst__toolbarButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
