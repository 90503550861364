.slick-prev {
  left: 10px;
  z-index: 1;
}
[dir="rtl"] .slick-prev {
  right: 10px;
  left: auto;
}

.slick-next {
  z-index: 1;
  right: 10px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;

  opacity: 0.75;
  color: #0f0c0c;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  outline: none;
}
