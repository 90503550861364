@import "~quill/dist/quill.snow.css";

.aor-rich-text-input .ql-editor {
  font-size: 1rem;
  font-family: "Roboto", "Manrope", "Rajdhani", monospace, serif;
  padding: 8px 8px 0 8px;
  border-bottom: 1px solid #e0e0e0;
  background-color: rgba(0, 0, 0, 0.1);
}

.aor-rich-text-input .ql-editor::after {
  content: "";
  display: block;
  border-bottom: 1px solid #00bcd4;

  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -moz-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -ms-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -o-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);

  -moz-transform: scaleX(0);
  -webkit-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
}

.aor-rich-text-input .ql-editor p:not(:last-child) {
  margin-bottom: 1rem;
}

.aor-rich-text-input .ql-editor strong {
  font-weight: 700;
}

.aor-rich-text-input .ql-editor h1,
.aor-rich-text-input .ql-editor h2,
.aor-rich-text-input .ql-editor h3 {
  margin: 1rem 0 0.5rem 0;
  font-weight: 500;
}

.aor-rich-text-input .ql-editor a {
  color: #00bcd4;
}

.aor-rich-text-input .ql-editor ul {
  margin-bottom: 1rem;
}

.aor-rich-text-input .ql-editor li:not(.ql-direction-rtl)::before {
  font-size: 0.5rem;
  position: relative;
  top: -0.2rem;
  margin-right: 0.5rem;
}

.aor-rich-text-input .ql-editor:focus::after {
  -moz-transform: scaleX(1);
  -webkit-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}

.aor-rich-text-input .ql-toolbar.ql-snow {
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
}

.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-item.ql-active,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-item:hover,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label.ql-selected,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label:hover {
  color: #00bcd4;
}

.aor-rich-text-input .ql-snow.ql-toolbar button:hover .ql-fill,
.aor-rich-text-input .ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: #00bcd4;
}

.aor-rich-text-input .ql-snow.ql-toolbar button:hover .ql-stroke,
.aor-rich-text-input .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  stroke: #00bcd4;
}

.aor-rich-text-input .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #fff;
  z-index: 10;
}

.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label {
  padding-left: 0;
}

.aor-rich-text-input .ql-snow.ql-toolbar + .ql-container.ql-snow {
  border: 0;
}

.aor-rich-text-input .ql-editor .ql-size-small {
  font-size: 0.75em;
}
.aor-rich-text-input .ql-editor .ql-size-large {
  font-size: 1.5em;
}
.aor-rich-text-input .ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.profile-img-container {
  position: relative;
  display: inline-block; /* added */
  overflow: hidden; /* added */
  margin: 16px;
}

.profile-img-container span:hover {
  opacity: 0.5;
  z-index: 11;
}

.profile-img-container span:hover + i {
  display: block;
  z-index: 20;
}

.profile-img-container i {
  width: 100%;
  position: absolute;
  text-align: center;
}

.profile-img-container span {
  position: absolute;
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", "Roboto";
}
.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Roboto"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  content: "Roboto";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Ubuntu"]::before {
  font-family: "Ubuntu", "Roboto";
}
.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Ubuntu"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Ubuntu"]::before {
  content: "Ubuntu";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="GreatVibes"]::before {
  font-family: "Great Vibes", "cursive";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="GreatVibes"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="GreatVibes"]::before {
  content: "Great Vibes";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="monospace"]::before {
  font-family: monospace;
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: monospace;
}
.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Manrope"]::before {
  font-family: "Manrope";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Manrope"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Manrope"]::before {
  content: "Manrope";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Rajdhani"]::before {
  font-family: "Rajdhani";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Rajdhani"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Rajdhani"]::before {
  content: "Rajdhani";
}

.aor-rich-text-input .ql-font-Roboto {
  font-family: "Roboto", "Roboto";
}

.aor-rich-text-input .ql-font-Rajdhani {
  font-family: "Rajdhani", "Roboto";
}

.aor-rich-text-input .ql-font-Manrope {
  font-family: "Manrope", "Roboto";
}

.aor-rich-text-input .ql-font-Ubuntu {
  font-family: "Ubuntu", "roboto";
}

.aor-rich-text-input .ql-font-GreatVibes {
  font-family: "Great Vibes", "cursive";
}

.aor-rich-text-input .ql-font-monospace {
  font-family: monospace;
}

.aor-rich-text-input .ql-editor p,
.aor-rich-text-input .ql-editor ol,
.aor-rich-text-input .ql-editor ul,
.aor-rich-text-input .ql-editor pre,
.aor-rich-text-input .ql-editor blockquote,
.aor-rich-text-input .ql-editor h1,
.aor-rich-text-input .ql-editor h2,
.aor-rich-text-input .ql-editor h3,
.aor-rich-text-input .ql-editor h4,
.aor-rich-text-input .ql-editor h5,
.aor-rich-text-input .ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.aor-rich-text-input .ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}
