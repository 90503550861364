@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/fontawesome.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/brands.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/regular.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/solid.min.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/svg-with-js.min.css);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&family=Rajdhani:wght@300;400;500;600;700&family=Manrope:wght@200;300;400;500;600;700;800&display=swap);
/* @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"); */
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v31/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. x */
  -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Manrope", "Rajdhani",
    "Roboto", "Oxygen", "Segoe UI", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
#root {
  overflow-x: hidden;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.cls-1 {
  fill-rule: evenodd;
  fill: #2196f3;
  color: #2196f3;
}
.logo-img {
  fill: #f00;
  color: #2196f3;
}

.logo-img path {
  fill: #f00;
  background-color: #f90;
}
.list-grid {
  padding-bottom: 16;
}

.list-grid .ra-input {
  width: 100%;
}

.item-list > * {
  margin: 4px;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.menu-container {
  background-color: transparent;
  z-index: 12000;
}
.ant-menu-submenu-popup {
  z-index: 12000;
}
.ant-menu {
  background-color: transparent;
}
.ant-menu-horizontal {
  z-index: 12000;
  border-bottom: 0px solid transparent;
}
.ant-menu-submenu-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.ant-menu-submenu-custom {
  display: flex;
  padding-right: 4px;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100; /* this is optional and should be different for every project */
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.bar-menu {
  width: 24px;
  height: 0.25rem;
  background: #cacaca;
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  -webkit-transform-origin: 1px;
          transform-origin: 1px;
}
.ant-popover {
  z-index: 91000;
}
.ant-popover .ant-popover-content {
  margin-left: -74px;
}
.ant-popover-content .ant-popover-inner-content {
  padding: 0px;
  padding-left: 20px;
  margin-left: 0px;
}
.ant-popover-placement-bottomLeft > .ant-popover-content > .ant-popover-arrow {
  left: 70px;
  top: 7px;
}

.banner-anim-elem .bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.banner-anim {
  position: relative;
  overflow: hidden;
  min-height: 100px;
}
.banner-anim-elem {
  height: 100%;
}
.banner-anim-elem > * {
  position: relative;
}
.banner-anim-elem-mask {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.banner-anim-thumb {
  position: absolute;
  bottom: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  text-align: center;
  pointer-events: none;
  z-index: 10;
}
.banner-anim-thumb > span {
  pointer-events: auto;
  cursor: pointer;
  display: inline-block;
  list-style: none;
}
.banner-anim-thumb-default {
  height: 40px;
  line-height: 40px;
}
.banner-anim-thumb-default span {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  margin: 0 5px;
  background: rgba(102, 102, 102, 0.35);
  transition: background 0.3s;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.25);
}
.banner-anim-thumb-default span:active,
.banner-anim-thumb-default span.active {
  background: #fff;
}

.product-list-reactactive {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

ul.nav {
  display: block;
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.nav > li {
  display: block;
  float: left;
  line-height: 62px;
  padding: 0 8px;
}

ul li a {
  position: relative;
  text-decoration: none;
}

ul.nav li > ul {
  position: absolute;
  display: none;
  width: 100%;
  z-index: 99;
}

ul.nav li > ul li {
  position: relative;
  display: block;
  float: left;
  z-index: 999;
}

ul.nav li > ul li a {
  display: block;
  padding: 0 8px;
  line-height: 62px;
}
ul.nav li:hover > ul {
  display: block;
  width: 100%;
  left: 0;
}

ul.nav li:hover > ul > li {
  display: block;
}

ul.nav:first-child li > ul:before {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #000000;
}

ul.nav:first-child li > ul:after {
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #000000;
}
/**/

/** big sub-menu dropdown arrow **/
ul.nav:first-child > li:hover > a:not(:last-child):after {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  top: 25px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  /* border-top: 15px solid #474747; */
  z-index: 9999;
}

ul.nav li:hover > ul > li {
  display: block;
  /* Safari 4+ */

  -webkit-animation: nav-down 0.6s;
  /* Fx 5+ */
  /* Opera 12+ */
  /* IE 10+, Fx 29+ */

  animation: nav-down 0.6s;
  /* transform-origin: 50% 50%;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-name: nav-down; */
}
/** Dropdown Animation **/

/** Sub-Nav Sliding Animation **/
@-webkit-keyframes nav-down {
  from {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  to {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

@keyframes nav-down {
  from {
    width: 0px;
    height: 0px;
    opacity: 0;
  }
  to {
    width: 100%;
    height: 100%;
    opacity: 1;
  }
}

.menu-view-4 #searchbox-downshift-input {
  border-radius: 0px;
  background-color: transparent;
  color: #ffffff;
}
.menu-view-4 .search-input-dark::-webkit-input-placeholder {
  color: #ffffff;
}
.menu-view-4 .search-input-dark:-ms-input-placeholder {
  color: #ffffff;
}
.menu-view-4 .search-input-dark::placeholder {
  color: #ffffff;
}
.menu-view-4 .search-input-light::-webkit-input-placeholder {
  color: #000000;
}
.menu-view-4 .search-input-light:-ms-input-placeholder {
  color: #000000;
}
.menu-view-4 .search-input-light::placeholder {
  color: #000000;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.menu-side-bar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.menu-side-bar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.list-slide-view .slick-list {
  display: flex;
}
.list-slide-view .slick-list .slick-track {
  display: flex;
}
.list-slide-view .slick-list .slick-track > div > div {
  width: 100%;
  height: 100%;
}

/* Config */
:root {
  --sk-size: 40px;
  --sk-color: #333;
}

/* Utility class for centering */
.sk-center {
  margin: auto;
}

/*  Plane
      <div class="sk-plane"></div>
 */
.sk-plane {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  background-color: #333;
  background-color: var(--sk-color);
  -webkit-animation: sk-plane 1.2s infinite ease-in-out;
          animation: sk-plane 1.2s infinite ease-in-out;
}

@-webkit-keyframes sk-plane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

@keyframes sk-plane {
  0% {
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
            transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
            transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
            transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}

/*  Chase
      <div class="sk-chase">
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
        <div class="sk-chase-dot"></div>
      </div>
 */
.sk-chase {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
  -webkit-animation: sk-chase 2.5s infinite linear both;
          animation: sk-chase 2.5s infinite linear both;
}

.sk-chase-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-animation: sk-chase-dot 2s infinite ease-in-out both;
          animation: sk-chase-dot 2s infinite ease-in-out both;
}

.sk-chase-dot:before {
  content: "";
  display: block;
  width: 25%;
  height: 25%;
  background-color: #333;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-chase-dot-before 2s infinite ease-in-out both;
          animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.sk-chase-dot:nth-child(1) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-chase-dot:nth-child(3) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5) {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6) {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.sk-chase-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-chase-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-chase-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-chase-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-chase-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-chase-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}

@-webkit-keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-chase {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-chase-dot {
  80%,
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  100%,
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-chase-dot-before {
  50% {
    -webkit-transform: scale(0.4);
            transform: scale(0.4);
  }
  100%,
  0% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*  Bounce
      <div class="sk-bounce">
        <div class="sk-bounce-dot"></div>
        <div class="sk-bounce-dot"></div>
      </div>
  */
.sk-bounce {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
}

.sk-bounce-dot {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #333;
  background-color: var(--sk-color);
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation: sk-bounce 2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.sk-bounce-dot:nth-child(2) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  45%,
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  45%,
  55% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*  Wave
      <div class="sk-wave">
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
        <div class="sk-wave-rect"></div>
      </div>
 */
.sk-wave {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  display: flex;
  justify-content: space-between;
}

.sk-wave-rect {
  background-color: #333;
  background-color: var(--sk-color);
  height: 100%;
  width: 15%;
  -webkit-animation: sk-wave 1.2s infinite ease-in-out;
          animation: sk-wave 1.2s infinite ease-in-out;
}

.sk-wave-rect:nth-child(1) {
  -webkit-animation-delay: -1.2s;
          animation-delay: -1.2s;
}
.sk-wave-rect:nth-child(2) {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-wave-rect:nth-child(3) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-wave-rect:nth-child(4) {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-wave-rect:nth-child(5) {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}

@-webkit-keyframes sk-wave {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

@keyframes sk-wave {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
            transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
  }
}

/*  Pulse
      <div class="sk-pulse"></div>
 */
.sk-pulse {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  background-color: #333;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
          animation: sk-pulse 1.2s infinite cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

@-webkit-keyframes sk-pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

@keyframes sk-pulse {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0;
  }
}

/*  Flow
      <div class="sk-flow">
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
        <div class="sk-flow-dot"></div>
      </div>
 */
.sk-flow {
  width: calc(40px * 1.3);
  width: calc(var(--sk-size) * 1.3);
  height: calc(40px * 1.3);
  height: calc(var(--sk-size) * 1.3);
  display: flex;
  justify-content: space-between;
}

.sk-flow-dot {
  width: 25%;
  height: 25%;
  background-color: #333;
  background-color: var(--sk-color);
  border-radius: 50%;
  -webkit-animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite
    both;
          animation: sk-flow 1.4s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0s infinite
    both;
}

.sk-flow-dot:nth-child(1) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-flow-dot:nth-child(2) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}

@-webkit-keyframes sk-flow {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-flow {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0.3);
            transform: scale(0.3);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*  Swing
      <div class="sk-swing">
        <div class="sk-swing-dot"></div>
        <div class="sk-swing-dot"></div>
      </div>
 */
.sk-swing {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
  -webkit-animation: sk-swing 1.8s infinite linear;
          animation: sk-swing 1.8s infinite linear;
}

.sk-swing-dot {
  width: 45%;
  height: 45%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  background-color: #333;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-swing-dot 2s infinite ease-in-out;
          animation: sk-swing-dot 2s infinite ease-in-out;
}

.sk-swing-dot:nth-child(2) {
  top: auto;
  bottom: 0;
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

@-webkit-keyframes sk-swing {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes sk-swing {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-swing-dot {
  0%,
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-swing-dot {
  0%,
  100% {
    -webkit-transform: scale(0.2);
            transform: scale(0.2);
  }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*  Circle
      <div class="sk-circle">
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
        <div class="sk-circle-dot"></div>
      </div>
 */
.sk-circle {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
}

.sk-circle-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-dot:before {
  content: "";
  display: block;
  width: 15%;
  height: 15%;
  background-color: #333;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-circle 1.2s infinite ease-in-out both;
          animation: sk-circle 1.2s infinite ease-in-out both;
}

.sk-circle-dot:nth-child(1) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-circle-dot:nth-child(2) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-circle-dot:nth-child(3) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-circle-dot:nth-child(4) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-circle-dot:nth-child(5) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-circle-dot:nth-child(6) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-circle-dot:nth-child(7) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-circle-dot:nth-child(8) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-circle-dot:nth-child(9) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-circle-dot:nth-child(10) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.sk-circle-dot:nth-child(11) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.sk-circle-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-circle-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-circle-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-circle-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-circle-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-circle-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.sk-circle-dot:nth-child(7):before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.sk-circle-dot:nth-child(8):before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-circle-dot:nth-child(9):before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-circle-dot:nth-child(10):before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle-dot:nth-child(11):before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circle {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circle {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*  Circle Fade
      <div class="sk-circle-fade">
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
        <div class="sk-circle-fade-dot"></div>
      </div>
 */
.sk-circle-fade {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
}

.sk-circle-fade-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-fade-dot:before {
  content: "";
  display: block;
  width: 15%;
  height: 15%;
  background-color: #333;
  background-color: var(--sk-color);
  border-radius: 100%;
  -webkit-animation: sk-circle-fade 1.2s infinite ease-in-out both;
          animation: sk-circle-fade 1.2s infinite ease-in-out both;
}

.sk-circle-fade-dot:nth-child(1) {
  -webkit-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-circle-fade-dot:nth-child(2) {
  -webkit-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-circle-fade-dot:nth-child(3) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-circle-fade-dot:nth-child(4) {
  -webkit-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-circle-fade-dot:nth-child(5) {
  -webkit-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-circle-fade-dot:nth-child(6) {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-circle-fade-dot:nth-child(7) {
  -webkit-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-circle-fade-dot:nth-child(8) {
  -webkit-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-circle-fade-dot:nth-child(9) {
  -webkit-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-circle-fade-dot:nth-child(10) {
  -webkit-transform: rotate(300deg);
          transform: rotate(300deg);
}
.sk-circle-fade-dot:nth-child(11) {
  -webkit-transform: rotate(330deg);
          transform: rotate(330deg);
}
.sk-circle-fade-dot:nth-child(1):before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s;
}
.sk-circle-fade-dot:nth-child(2):before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}
.sk-circle-fade-dot:nth-child(3):before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s;
}
.sk-circle-fade-dot:nth-child(4):before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s;
}
.sk-circle-fade-dot:nth-child(5):before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s;
}
.sk-circle-fade-dot:nth-child(6):before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s;
}
.sk-circle-fade-dot:nth-child(7):before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.sk-circle-fade-dot:nth-child(8):before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-circle-fade-dot:nth-child(9):before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-circle-fade-dot:nth-child(10):before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-circle-fade-dot:nth-child(11):before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circle-fade {
  0%,
  39%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  40% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes sk-circle-fade {
  0%,
  39%,
  100% {
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  40% {
    opacity: 1;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

/*  Grid
    <div class="sk-grid">
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
      <div class="sk-grid-cube"></div>
    </div>
 */
.sk-grid {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  /* Cube positions
   * 1 2 3
   * 4 5 6
   * 7 8 9
   */
}
.sk-grid-cube {
  width: 33.33%;
  height: 33.33%;
  background-color: #333;
  background-color: var(--sk-color);
  float: left;
  -webkit-animation: sk-grid 1.3s infinite ease-in-out;
          animation: sk-grid 1.3s infinite ease-in-out;
}
.sk-grid-cube:nth-child(1) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.sk-grid-cube:nth-child(2) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-grid-cube:nth-child(3) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
.sk-grid-cube:nth-child(4) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.sk-grid-cube:nth-child(5) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.sk-grid-cube:nth-child(6) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-grid-cube:nth-child(7) {
  -webkit-animation-delay: 0s;
          animation-delay: 0s;
}
.sk-grid-cube:nth-child(8) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
.sk-grid-cube:nth-child(9) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

@-webkit-keyframes sk-grid {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-grid {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
            transform: scale3D(0, 0, 1);
  }
}

/*  Fold
      <div class="sk-fold">
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
        <div class="sk-fold-cube"></div>
      </div>
 */
.sk-fold {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
  -webkit-transform: rotateZ(45deg);
          transform: rotateZ(45deg);
}
.sk-fold-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.sk-fold-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #333;
  background-color: var(--sk-color);
  -webkit-animation: sk-fold 2.4s infinite linear both;
          animation: sk-fold 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
          transform-origin: 100% 100%;
}
.sk-fold-cube:nth-child(2) {
  -webkit-transform: scale(1.1) rotateZ(90deg);
          transform: scale(1.1) rotateZ(90deg);
}
.sk-fold-cube:nth-child(4) {
  -webkit-transform: scale(1.1) rotateZ(180deg);
          transform: scale(1.1) rotateZ(180deg);
}
.sk-fold-cube:nth-child(3) {
  -webkit-transform: scale(1.1) rotateZ(270deg);
          transform: scale(1.1) rotateZ(270deg);
}
.sk-fold-cube:nth-child(2):before {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
.sk-fold-cube:nth-child(4):before {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.sk-fold-cube:nth-child(3):before {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes sk-fold {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-fold {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
            transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
            transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
            transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/*  Wander
      <div class="sk-wander">
        <div class="sk-wander-cube"></div>
        <div class="sk-wander-cube"></div>
        <div class="sk-wander-cube"></div>
        <div class="sk-wander-cube"></div>
      </div>
 */
.sk-wander {
  width: 40px;
  width: var(--sk-size);
  height: 40px;
  height: var(--sk-size);
  position: relative;
}

.sk-wander-cube {
  background-color: #333;
  background-color: var(--sk-color);
  width: 20%;
  height: 20%;
  position: absolute;
  top: 0;
  left: 0;
  --sk-wander-distance: calc(var(--sk-size) * 0.75);
  -webkit-animation: sk-wander 2s ease-in-out -2s infinite both;
          animation: sk-wander 2s ease-in-out -2s infinite both;
}
.sk-wander-cube:nth-child(2) {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s;
}
.sk-wander-cube:nth-child(3) {
  -webkit-animation-delay: -1s;
          animation-delay: -1s;
}

@-webkit-keyframes sk-wander {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
            transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
  }
  50% {
    /* Make FF rotate in the right direction */
    -webkit-transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-179deg);
            transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-180deg);
            transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(var(--sk-wander-distance))
      rotate(-270deg) scale(0.6);
            transform: translateX(0) translateY(var(--sk-wander-distance))
      rotate(-270deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

@keyframes sk-wander {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  25% {
    -webkit-transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
            transform: translateX(var(--sk-wander-distance)) rotate(-90deg) scale(0.6);
  }
  50% {
    /* Make FF rotate in the right direction */
    -webkit-transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-179deg);
            transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-179deg);
  }
  50.1% {
    -webkit-transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-180deg);
            transform: translateX(var(--sk-wander-distance))
      translateY(var(--sk-wander-distance)) rotate(-180deg);
  }
  75% {
    -webkit-transform: translateX(0) translateY(var(--sk-wander-distance))
      rotate(-270deg) scale(0.6);
            transform: translateX(0) translateY(var(--sk-wander-distance))
      rotate(-270deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(-360deg);
            transform: rotate(-360deg);
  }
}

.labelImage {
  position: relative;
  line-height: 22px;
  padding-top: 0px;
  top: 15px;
  margin-top: 1.5em;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  z-index: 1;
  -webkit-transform: scale(0.75) translate(0px, -28px);
          transform: scale(0.75) translate(0px, -28px);
  -webkit-transform-origin: left top 0px;
          transform-origin: left top 0px;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: #616161;
  font-size: 14pt;
  font-weight: bold;
  letter-spacing: 1px;
}

.handle {
  display: block;
  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z" color="#000"></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
}

.grid {
  display: block;
  width: 130 * 4px;
  height: 350px;
  white-space: nowrap;
  border: 0;
  background-color: transparent;
}

.gridItem {
  float: left;
  width: 130px;
  padding: 8px;
  background: transparent;
  border: 0;
}

.gridItem .wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fff;
  border: 1px solid #efefef;
  font-size: 28px;
}

.gridItem .wrapper span {
  display: none;
}

.auto-complete-collection-product .react-autosuggest__container {
  position: relative;
}

.auto-complete-collection-product .react-autosuggest__input {
  min-width: 300px;
  padding: 8px;
  font-weight: 300;
  font-size: 16px;
  border-bottom: 1px solid #aaa;
  -webkit-appearance: none;
}

.auto-complete-collection-product .react-autosuggest__input--focused {
  outline: none;
}

.auto-complete-collection-product .react-autosuggest__input::-ms-clear {
  display: none;
}

.auto-complete-collection-product .react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.auto-complete-collection-product .react-autosuggest__suggestions-container {
  display: none;
}

.auto-complete-collection-product
  .react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  overflow-y: auto;
  z-index: 2001;
  max-height: 300px;
  width: 500px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.auto-complete-collection-product .react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.auto-complete-collection-product .react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 16px;
}

.auto-complete-collection-product .react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.auto-complete-collection-product .react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.auto-complete-collection-product .react-autosuggest__section-container--first {
  border-top: 0;
}

.auto-complete-collection-product .react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.slide-in {
  opacity: 0;
  /* display: none; */
  /* animation: slide-in 1.5s forwards;
  -webkit-animation: slide-in 1.5s forwards; */
}

.slide-out {
  /* animation: slide-out 0.5s forwards;
  -webkit-animation: slide-out 0.5s forwards; */
  opacity: 1;
  top: 0;
  position: fixed;
  z-index: 31101;
}

@keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
}

@-webkit-keyframes slide-in {
  100% {
    -webkit-transform: translateX(0%);
  }
}

@keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
            transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
}

@-webkit-keyframes slide-out {
  0% {
    -webkit-transform: translateX(0%);
  }
  100% {
    -webkit-transform: translateX(-100%);
  }
}

.blockbuilder-counter-control {
    background-color: #FFF;
    border: 1px solid #DEE1E2;
    border-radius: 4px;
    display: inline-block;
    line-height: 0;
    font-size: 11px;
    font-weight: 700;
    margin-top: 3px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.blockbuilder-counter-control .blockbuilder-counter-control-btn {
    cursor: pointer;
    display: inline-block;
    height: 30px;
    width: 30px;
    text-align: center;
    line-height: 30px;
    vertical-align: middle;
}

.blockbuilder-counter-control .blockbuilder-counter-control-value {
    background-color: #F9F9F9;
    color: #7F7F7F;
    border: 0px;
    border-left: 1px solid #DEE1E2;
    border-right: 1px solid #DEE1E2;
    display: inline-block;
    height: 30px;
    width: 40px;
    text-align: center;
    line-height: 30px;
    padding: 0px;
    vertical-align: middle;
}

.aor-rich-text-input .ql-editor {
  font-size: 1rem;
  font-family: "Roboto", "Manrope", "Rajdhani", monospace, serif;
  padding: 8px 8px 0 8px;
  border-bottom: 1px solid #e0e0e0;
  background-color: rgba(0, 0, 0, 0.1);
}

.aor-rich-text-input .ql-editor::after {
  content: "";
  display: block;
  border-bottom: 1px solid #00bcd4;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
}

.aor-rich-text-input .ql-editor p:not(:last-child) {
  margin-bottom: 1rem;
}

.aor-rich-text-input .ql-editor strong {
  font-weight: 700;
}

.aor-rich-text-input .ql-editor h1,
.aor-rich-text-input .ql-editor h2,
.aor-rich-text-input .ql-editor h3 {
  margin: 1rem 0 0.5rem 0;
  font-weight: 500;
}

.aor-rich-text-input .ql-editor a {
  color: #00bcd4;
}

.aor-rich-text-input .ql-editor ul {
  margin-bottom: 1rem;
}

.aor-rich-text-input .ql-editor li:not(.ql-direction-rtl)::before {
  font-size: 0.5rem;
  position: relative;
  top: -0.2rem;
  margin-right: 0.5rem;
}

.aor-rich-text-input .ql-editor:focus::after {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.aor-rich-text-input .ql-toolbar.ql-snow {
  margin: 0.5rem 0;
  border: 0;
  padding: 0;
}

.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-item.ql-active,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-item:hover,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label.ql-active,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label.ql-selected,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label:hover {
  color: #00bcd4;
}

.aor-rich-text-input .ql-snow.ql-toolbar button:hover .ql-fill,
.aor-rich-text-input .ql-snow.ql-toolbar button.ql-active .ql-fill {
  fill: #00bcd4;
}

.aor-rich-text-input .ql-snow.ql-toolbar button:hover .ql-stroke,
.aor-rich-text-input .ql-snow.ql-toolbar button.ql-active .ql-stroke,
.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  stroke: #00bcd4;
}

.aor-rich-text-input .ql-snow .ql-picker.ql-expanded .ql-picker-options {
  background: #fff;
  z-index: 10;
}

.aor-rich-text-input .ql-snow.ql-toolbar .ql-picker-label {
  padding-left: 0;
}

.aor-rich-text-input .ql-snow.ql-toolbar + .ql-container.ql-snow {
  border: 0;
}

.aor-rich-text-input .ql-editor .ql-size-small {
  font-size: 0.75em;
}
.aor-rich-text-input .ql-editor .ql-size-large {
  font-size: 1.5em;
}
.aor-rich-text-input .ql-editor .ql-size-huge {
  font-size: 2.5em;
}

.profile-img-container {
  position: relative;
  display: inline-block; /* added */
  overflow: hidden; /* added */
  margin: 16px;
}

.profile-img-container span:hover {
  opacity: 0.5;
  z-index: 11;
}

.profile-img-container span:hover + i {
  display: block;
  z-index: 20;
}

.profile-img-container i {
  width: 100%;
  position: absolute;
  text-align: center;
}

.profile-img-container span {
  position: absolute;
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", "Roboto";
}
.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Roboto"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  content: "Roboto";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Ubuntu"]::before {
  font-family: "Ubuntu", "Roboto";
}
.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Ubuntu"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Ubuntu"]::before {
  content: "Ubuntu";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="GreatVibes"]::before {
  font-family: "Great Vibes", "cursive";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="GreatVibes"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="GreatVibes"]::before {
  content: "Great Vibes";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="monospace"]::before {
  font-family: monospace;
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: monospace;
}
.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Manrope"]::before {
  font-family: "Manrope";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Manrope"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Manrope"]::before {
  content: "Manrope";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="Rajdhani"]::before {
  font-family: "Rajdhani";
}

.aor-rich-text-input
  .ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="Rajdhani"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Rajdhani"]::before {
  content: "Rajdhani";
}

.aor-rich-text-input .ql-font-Roboto {
  font-family: "Roboto", "Roboto";
}

.aor-rich-text-input .ql-font-Rajdhani {
  font-family: "Rajdhani", "Roboto";
}

.aor-rich-text-input .ql-font-Manrope {
  font-family: "Manrope", "Roboto";
}

.aor-rich-text-input .ql-font-Ubuntu {
  font-family: "Ubuntu", "roboto";
}

.aor-rich-text-input .ql-font-GreatVibes {
  font-family: "Great Vibes", "cursive";
}

.aor-rich-text-input .ql-font-monospace {
  font-family: monospace;
}

.aor-rich-text-input .ql-editor p,
.aor-rich-text-input .ql-editor ol,
.aor-rich-text-input .ql-editor ul,
.aor-rich-text-input .ql-editor pre,
.aor-rich-text-input .ql-editor blockquote,
.aor-rich-text-input .ql-editor h1,
.aor-rich-text-input .ql-editor h2,
.aor-rich-text-input .ql-editor h3,
.aor-rich-text-input .ql-editor h4,
.aor-rich-text-input .ql-editor h5,
.aor-rich-text-input .ql-editor h6 {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.aor-rich-text-input .ql-snow .ql-editor blockquote {
  border-left: 4px solid #ccc;
  margin-bottom: 5px;
  margin-top: 5px;
  padding-left: 16px;
}

/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v31/2fcrYFNaTjcS6g4U3t-Y5UEw0lE80llgEseQY3FEmqw.woff2)
    format("woff2");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
}

h1 {
  margin: 0px;
}
.contents-actions {
  z-index: 2;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media only screen and (min-width: 768px) {
  .contents-actions {
    float: right;
  }
}
.fullheight {
  min-height: 90vh;
  -ms-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 1px;
  padding-bottom: 20px;
}

.padding {
  padding: 16px;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.container:after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 576px) {
  .container {
    width: 540px;
    max-width: 100%;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
}
.container-fluid {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0;
  padding-right: 0;
}
.container-fluid:after {
  content: "";
  display: table;
  clear: both;
}
.row {
  margin-right: 0;
  margin-left: 0;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}
@media (min-width: 576px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 768px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 992px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}
@media (min-width: 1200px) {
  .row {
    margin-right: 0;
    margin-left: 0;
  }
}
.col-lg,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-md,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-sm,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-xl,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  position: relative;
  min-height: 1px;
  padding-right: 0;
  padding-left: 0;
}
@media (min-width: 576px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 768px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 992px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (min-width: 1200px) {
  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-xl,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xs,
  .col-xs-1,
  .col-xs-2,
  .col-xs-3,
  .col-xs-4,
  .col-xs-5,
  .col-xs-6,
  .col-xs-7,
  .col-xs-8,
  .col-xs-9,
  .col-xs-10,
  .col-xs-11,
  .col-xs-12 {
    padding-right: 0;
    padding-left: 0;
  }
}
.col-xs-1 {
  float: left;
  width: 8.33333%;
}
.col-xs-2 {
  float: left;
  width: 16.66667%;
}
.col-xs-3 {
  float: left;
  width: 25%;
}
.col-xs-4 {
  float: left;
  width: 33.33333%;
}
.col-xs-5 {
  float: left;
  width: 41.66667%;
}
.col-xs-6 {
  float: left;
  width: 50%;
}
.col-xs-7 {
  float: left;
  width: 58.33333%;
}
.col-xs-8 {
  float: left;
  width: 66.66667%;
}
.col-xs-9 {
  float: left;
  width: 75%;
}
.col-xs-10 {
  float: left;
  width: 83.33333%;
}
.col-xs-11 {
  float: left;
  width: 91.66667%;
}
.col-xs-12 {
  float: left;
  width: 100%;
}
.pull-xs-0 {
  right: auto;
}
.pull-xs-1 {
  right: 8.33333%;
}
.pull-xs-2 {
  right: 16.66667%;
}
.pull-xs-3 {
  right: 25%;
}
.pull-xs-4 {
  right: 33.33333%;
}
.pull-xs-5 {
  right: 41.66667%;
}
.pull-xs-6 {
  right: 50%;
}
.pull-xs-7 {
  right: 58.33333%;
}
.pull-xs-8 {
  right: 66.66667%;
}
.pull-xs-9 {
  right: 75%;
}
.pull-xs-10 {
  right: 83.33333%;
}
.pull-xs-11 {
  right: 91.66667%;
}
.pull-xs-12 {
  right: 100%;
}
.push-xs-0 {
  left: auto;
}
.push-xs-1 {
  left: 8.33333%;
}
.push-xs-2 {
  left: 16.66667%;
}
.push-xs-3 {
  left: 25%;
}
.push-xs-4 {
  left: 33.33333%;
}
.push-xs-5 {
  left: 41.66667%;
}
.push-xs-6 {
  left: 50%;
}
.push-xs-7 {
  left: 58.33333%;
}
.push-xs-8 {
  left: 66.66667%;
}
.push-xs-9 {
  left: 75%;
}
.push-xs-10 {
  left: 83.33333%;
}
.push-xs-11 {
  left: 91.66667%;
}
.push-xs-12 {
  left: 100%;
}
.offset-xs-1 {
  margin-left: 8.33333%;
}
.offset-xs-2 {
  margin-left: 16.66667%;
}
.offset-xs-3 {
  margin-left: 25%;
}
.offset-xs-4 {
  margin-left: 33.33333%;
}
.offset-xs-5 {
  margin-left: 41.66667%;
}
.offset-xs-6 {
  margin-left: 50%;
}
.offset-xs-7 {
  margin-left: 58.33333%;
}
.offset-xs-8 {
  margin-left: 66.66667%;
}
.offset-xs-9 {
  margin-left: 75%;
}
.offset-xs-10 {
  margin-left: 83.33333%;
}
.offset-xs-11 {
  margin-left: 91.66667%;
}
@media (min-width: 576px) {
  .col-sm-1 {
    float: left;
    width: 8.33333%;
  }
  .col-sm-2 {
    float: left;
    width: 16.66667%;
  }
  .col-sm-3 {
    float: left;
    width: 25%;
  }
  .col-sm-4 {
    float: left;
    width: 33.33333%;
  }
  .col-sm-5 {
    float: left;
    width: 41.66667%;
  }
  .col-sm-6 {
    float: left;
    width: 50%;
  }
  .col-sm-7 {
    float: left;
    width: 58.33333%;
  }
  .col-sm-8 {
    float: left;
    width: 66.66667%;
  }
  .col-sm-9 {
    float: left;
    width: 75%;
  }
  .col-sm-10 {
    float: left;
    width: 83.33333%;
  }
  .col-sm-11 {
    float: left;
    width: 91.66667%;
  }
  .col-sm-12 {
    float: left;
    width: 100%;
  }
  .pull-sm-0 {
    right: auto;
  }
  .pull-sm-1 {
    right: 8.33333%;
  }
  .pull-sm-2 {
    right: 16.66667%;
  }
  .pull-sm-3 {
    right: 25%;
  }
  .pull-sm-4 {
    right: 33.33333%;
  }
  .pull-sm-5 {
    right: 41.66667%;
  }
  .pull-sm-6 {
    right: 50%;
  }
  .pull-sm-7 {
    right: 58.33333%;
  }
  .pull-sm-8 {
    right: 66.66667%;
  }
  .pull-sm-9 {
    right: 75%;
  }
  .pull-sm-10 {
    right: 83.33333%;
  }
  .pull-sm-11 {
    right: 91.66667%;
  }
  .pull-sm-12 {
    right: 100%;
  }
  .push-sm-0 {
    left: auto;
  }
  .push-sm-1 {
    left: 8.33333%;
  }
  .push-sm-2 {
    left: 16.66667%;
  }
  .push-sm-3 {
    left: 25%;
  }
  .push-sm-4 {
    left: 33.33333%;
  }
  .push-sm-5 {
    left: 41.66667%;
  }
  .push-sm-6 {
    left: 50%;
  }
  .push-sm-7 {
    left: 58.33333%;
  }
  .push-sm-8 {
    left: 66.66667%;
  }
  .push-sm-9 {
    left: 75%;
  }
  .push-sm-10 {
    left: 83.33333%;
  }
  .push-sm-11 {
    left: 91.66667%;
  }
  .push-sm-12 {
    left: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333%;
  }
  .offset-sm-2 {
    margin-left: 16.66667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333%;
  }
  .offset-sm-5 {
    margin-left: 41.66667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333%;
  }
  .offset-sm-8 {
    margin-left: 66.66667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333%;
  }
  .offset-sm-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 768px) {
  .col-md-1 {
    float: left;
    width: 8.33333%;
  }
  .col-md-2 {
    float: left;
    width: 16.66667%;
  }
  .col-md-3 {
    float: left;
    width: 25%;
  }
  .col-md-4 {
    float: left;
    width: 33.33333%;
  }
  .col-md-5 {
    float: left;
    width: 41.66667%;
  }
  .col-md-6 {
    float: left;
    width: 50%;
  }
  .col-md-7 {
    float: left;
    width: 58.33333%;
  }
  .col-md-8 {
    float: left;
    width: 66.66667%;
  }
  .col-md-9 {
    float: left;
    width: 75%;
  }
  .col-md-10 {
    float: left;
    width: 83.33333%;
  }
  .col-md-11 {
    float: left;
    width: 91.66667%;
  }
  .col-md-12 {
    float: left;
    width: 100%;
  }
  .pull-md-0 {
    right: auto;
  }
  .pull-md-1 {
    right: 8.33333%;
  }
  .pull-md-2 {
    right: 16.66667%;
  }
  .pull-md-3 {
    right: 25%;
  }
  .pull-md-4 {
    right: 33.33333%;
  }
  .pull-md-5 {
    right: 41.66667%;
  }
  .pull-md-6 {
    right: 50%;
  }
  .pull-md-7 {
    right: 58.33333%;
  }
  .pull-md-8 {
    right: 66.66667%;
  }
  .pull-md-9 {
    right: 75%;
  }
  .pull-md-10 {
    right: 83.33333%;
  }
  .pull-md-11 {
    right: 91.66667%;
  }
  .pull-md-12 {
    right: 100%;
  }
  .push-md-0 {
    left: auto;
  }
  .push-md-1 {
    left: 8.33333%;
  }
  .push-md-2 {
    left: 16.66667%;
  }
  .push-md-3 {
    left: 25%;
  }
  .push-md-4 {
    left: 33.33333%;
  }
  .push-md-5 {
    left: 41.66667%;
  }
  .push-md-6 {
    left: 50%;
  }
  .push-md-7 {
    left: 58.33333%;
  }
  .push-md-8 {
    left: 66.66667%;
  }
  .push-md-9 {
    left: 75%;
  }
  .push-md-10 {
    left: 83.33333%;
  }
  .push-md-11 {
    left: 91.66667%;
  }
  .push-md-12 {
    left: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333%;
  }
  .offset-md-2 {
    margin-left: 16.66667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333%;
  }
  .offset-md-5 {
    margin-left: 41.66667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333%;
  }
  .offset-md-8 {
    margin-left: 66.66667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333%;
  }
  .offset-md-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 992px) {
  .col-lg-1 {
    float: left;
    width: 8.33333%;
  }
  .col-lg-2 {
    float: left;
    width: 16.66667%;
  }
  .col-lg-3 {
    float: left;
    width: 25%;
  }
  .col-lg-4 {
    float: left;
    width: 33.33333%;
  }
  .col-lg-5 {
    float: left;
    width: 41.66667%;
  }
  .col-lg-6 {
    float: left;
    width: 50%;
  }
  .col-lg-7 {
    float: left;
    width: 58.33333%;
  }
  .col-lg-8 {
    float: left;
    width: 66.66667%;
  }
  .col-lg-9 {
    float: left;
    width: 75%;
  }
  .col-lg-10 {
    float: left;
    width: 83.33333%;
  }
  .col-lg-11 {
    float: left;
    width: 91.66667%;
  }
  .col-lg-12 {
    float: left;
    width: 100%;
  }
  .pull-lg-0 {
    right: auto;
  }
  .pull-lg-1 {
    right: 8.33333%;
  }
  .pull-lg-2 {
    right: 16.66667%;
  }
  .pull-lg-3 {
    right: 25%;
  }
  .pull-lg-4 {
    right: 33.33333%;
  }
  .pull-lg-5 {
    right: 41.66667%;
  }
  .pull-lg-6 {
    right: 50%;
  }
  .pull-lg-7 {
    right: 58.33333%;
  }
  .pull-lg-8 {
    right: 66.66667%;
  }
  .pull-lg-9 {
    right: 75%;
  }
  .pull-lg-10 {
    right: 83.33333%;
  }
  .pull-lg-11 {
    right: 91.66667%;
  }
  .pull-lg-12 {
    right: 100%;
  }
  .push-lg-0 {
    left: auto;
  }
  .push-lg-1 {
    left: 8.33333%;
  }
  .push-lg-2 {
    left: 16.66667%;
  }
  .push-lg-3 {
    left: 25%;
  }
  .push-lg-4 {
    left: 33.33333%;
  }
  .push-lg-5 {
    left: 41.66667%;
  }
  .push-lg-6 {
    left: 50%;
  }
  .push-lg-7 {
    left: 58.33333%;
  }
  .push-lg-8 {
    left: 66.66667%;
  }
  .push-lg-9 {
    left: 75%;
  }
  .push-lg-10 {
    left: 83.33333%;
  }
  .push-lg-11 {
    left: 91.66667%;
  }
  .push-lg-12 {
    left: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333%;
  }
  .offset-lg-2 {
    margin-left: 16.66667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333%;
  }
  .offset-lg-5 {
    margin-left: 41.66667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333%;
  }
  .offset-lg-8 {
    margin-left: 66.66667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333%;
  }
  .offset-lg-11 {
    margin-left: 91.66667%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1 {
    float: left;
    width: 8.33333%;
  }
  .col-xl-2 {
    float: left;
    width: 16.66667%;
  }
  .col-xl-3 {
    float: left;
    width: 25%;
  }
  .col-xl-4 {
    float: left;
    width: 33.33333%;
  }
  .col-xl-5 {
    float: left;
    width: 41.66667%;
  }
  .col-xl-6 {
    float: left;
    width: 50%;
  }
  .col-xl-7 {
    float: left;
    width: 58.33333%;
  }
  .col-xl-8 {
    float: left;
    width: 66.66667%;
  }
  .col-xl-9 {
    float: left;
    width: 75%;
  }
  .col-xl-10 {
    float: left;
    width: 83.33333%;
  }
  .col-xl-11 {
    float: left;
    width: 91.66667%;
  }
  .col-xl-12 {
    float: left;
    width: 100%;
  }
  .pull-xl-0 {
    right: auto;
  }
  .pull-xl-1 {
    right: 8.33333%;
  }
  .pull-xl-2 {
    right: 16.66667%;
  }
  .pull-xl-3 {
    right: 25%;
  }
  .pull-xl-4 {
    right: 33.33333%;
  }
  .pull-xl-5 {
    right: 41.66667%;
  }
  .pull-xl-6 {
    right: 50%;
  }
  .pull-xl-7 {
    right: 58.33333%;
  }
  .pull-xl-8 {
    right: 66.66667%;
  }
  .pull-xl-9 {
    right: 75%;
  }
  .pull-xl-10 {
    right: 83.33333%;
  }
  .pull-xl-11 {
    right: 91.66667%;
  }
  .pull-xl-12 {
    right: 100%;
  }
  .push-xl-0 {
    left: auto;
  }
  .push-xl-1 {
    left: 8.33333%;
  }
  .push-xl-2 {
    left: 16.66667%;
  }
  .push-xl-3 {
    left: 25%;
  }
  .push-xl-4 {
    left: 33.33333%;
  }
  .push-xl-5 {
    left: 41.66667%;
  }
  .push-xl-6 {
    left: 50%;
  }
  .push-xl-7 {
    left: 58.33333%;
  }
  .push-xl-8 {
    left: 66.66667%;
  }
  .push-xl-9 {
    left: 75%;
  }
  .push-xl-10 {
    left: 83.33333%;
  }
  .push-xl-11 {
    left: 91.66667%;
  }
  .push-xl-12 {
    left: 100%;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.33333%;
  }
  .offset-xl-2 {
    margin-left: 16.66667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.33333%;
  }
  .offset-xl-5 {
    margin-left: 41.66667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.33333%;
  }
  .offset-xl-8 {
    margin-left: 66.66667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.33333%;
  }
  .offset-xl-11 {
    margin-left: 91.66667%;
  }
}

.mobile-view .col-xs-12 {
  float: left;
  width: 320px;
}

.error-field {
  -webkit-animation-name: shake;
  animation-name: shake;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.error-field input,
.error-field textarea {
  border-color: #a94442 !important;
  color: #a94442 !important;
}
.field-error {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  top: -20px;
  right: 10px;
}
.field-error:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(136, 183, 213, 0);
  border-top-color: #ebcccc;
  border-width: 5px;
  margin-left: -5px;
}
.spinner {
  margin: 0 auto;
  width: 70px;
  text-align: center;
}
.spinner > div {
  width: 12px;
  height: 12px;
  background-color: hsla(0, 0%, 100%, 0.5);
  margin: 0 2px;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}
.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bouncedelay {
  0%,
  80%,
  to {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes shake {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}
@keyframes shake {
  0%,
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    -webkit-transform: translate3d(-10px, 0, 0);
    transform: translate3d(-10px, 0, 0);
  }
  20%,
  40%,
  60%,
  80% {
    -webkit-transform: translate3d(10px, 0, 0);
    transform: translate3d(10px, 0, 0);
  }
}

.slick-prev {
  left: 10px;
  z-index: 1;
}
[dir="rtl"] .slick-prev {
  right: 10px;
  left: auto;
}

.slick-next {
  z-index: 1;
  right: 10px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;

  opacity: 0.75;
  color: #0f0c0c;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  outline: none;
}

.react-autosuggest__container {
  position: relative;
}

.react-autosuggest__input {
  min-width: 300px;
  padding: 8px;
  font-weight: 300;
  font-size: 16px;
  border-bottom: 1px solid #aaa;
  -webkit-appearance: none;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input::-ms-clear {
  display: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  overflow-y: auto;
  z-index: 2001;
  max-height: 300px;
  width: 300px;
  border: 1px solid #aaa;
  background-color: #fff;
  font-weight: 300;
  font-size: 16px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 8px 16px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}

.slick-prev {
  left: 10px;
  z-index: 1;
}
[dir="rtl"] .slick-prev {
  right: 10px;
  left: auto;
}

.slick-next {
  z-index: 1;
  right: 10px;
}
[dir="rtl"] .slick-next {
  right: auto;
  left: 10px;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 30px;
  line-height: 1;

  opacity: 0.75;
  color: #0f0c0c;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-slider {
  outline: none;
}

.rst__rowContents {
  min-width: 400px;
  box-shadow: none;
}
.rst__moveHandle {
  box-shadow: none;
}
.rst__placeholder {
  max-width: 100%;
}
.rst__toolbarButton {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

